<template>
  <base-section id="about-our-product">
    <base-section-heading color="grey lighten-2" icon="mdi-vuetify" title="About Us" />

    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Mission statement',
          subtitle: '-----',
          text: `A dynamic , transparent and reliable financial institute aimed towards the ordinary individual who desires success and is willing to take the steps forward to financial freedom.`,
          callout: '01',
        },
        {
          title: 'Vision statement',
          subtitle: '---',
          text:
            'To be the leading micro financing institute for the BPO sector in Jamaica',
          callout: '02',
        },
        {
          title: 'Core values',
          subtitle: '---',
          text: 'Dynamic, transparent, reliable, rational, lucrative',
          callout: '03',
        },
      ],
    }),
  }
</script>
